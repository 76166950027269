import React from 'react'
import { useCookies } from '@src/utils/cookies'
import { homeRoute } from '@src/routes'
import { Link } from '@src/components/Link'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme, Container } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import CompanyLogo from '@src/components/CompanyLogo'
import { sendHeaderTopMenuEvents } from '@utils/analytics'
import { EventValues } from '@sial/common-utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    simpleHeader: {
      height: 60,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 2px 4px rgba(0,0,0,.16)',
      borderBottom: `3px solid ${theme.palette.brand.main}`,
    },
    headerLogo: {
      display: 'flex',
    },
    logoMS: { height: 34, fontSize: 135 },
    logoROW: { height: 24, fontSize: 160 },
    '@media print': {
      simpleHeader: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
      },
    },
  })
)
interface SimpleHeaderProps {
  hideLink?: boolean
  fixedHeader?: boolean
}

const SimpleHeader: React.FC<SimpleHeaderProps> = ({
  hideLink = false,
  fixedHeader = false,
}) => {
  const classes = useStyles()
  const [cookies] = useCookies(['_ga'])
  const companyLogo = (
    <CompanyLogo
      color="secondary"
      msClassName={classes.logoMS}
      rowClassName={classes.logoROW}
    />
  )
  return (
    <AppBar
      className={classes.simpleHeader}
      position={fixedHeader ? 'fixed' : 'static'}
    >
      <Container maxWidth="lg">
        {/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        {hideLink ? (
          <span className={classes.headerLogo}>{companyLogo}</span>
        ) : (
          <Link href={homeRoute.index()}>
            <a
              aria-label="navigate-home"
              role="link"
              tabIndex={0}
              className={classes.headerLogo}
              onClick={() =>
                sendHeaderTopMenuEvents(EventValues.Logo, cookies._ga || '')
              }
            >
              {companyLogo}
            </a>
          </Link>
        )}
      </Container>
    </AppBar>
  )
}

export default SimpleHeader
